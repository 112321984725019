
$datepicker__highlighted-color: #444;
$datepicker__selected-color: rgb(62, 140, 101);
$datepicker__muted-color: $datepicker__selected-color;
$datepicker__border-color: $datepicker__selected-color;
$datepicker__background-color: lighten($datepicker__selected-color, 30%);
$datepicker__text-color: #444;
$datepicker__header-color: #444;
$datepicker__navigation-disabled-color: transparentize(lighten($datepicker__background-color, 10%), 0.7);

$datepicker__border-radius: 2px;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: 0.8rem;
$datepicker__font-family: inherit;
$datepicker__item-size: 1.7rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 6px;

@import 'react-datepicker/src/stylesheets/datepicker.scss';


.react-datepicker__day {
    user-select: none;
}
.partial-pick .react-datepicker__day {
  color: lighten(#444, 50%);
  &:hover {
    background-color: white;
    color: lighten(#444, 50%);
  }

  &--highlighted {
      background-color: transparent;
      color: #444;

      &:hover {
          color: #444;
          background-color: $datepicker__background-color;
      }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__selected-color;
      color: #fff;

      &:hover {
          background-color: darken($datepicker__selected-color, 5%);
          color: #fff;
      }
  }
}
